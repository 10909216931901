const getTitle = () =>
{
  if(process.env.VUE_APP_SERVICE_TYPE == 'ZIU PAY')
  {
    return "Ziupay";
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'PCX PAY')
  {
    return "PCX PAY";
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'SIGMA PAY')
  {
    return "SIGMA PAY";
  }
}

const getFavicon = () =>
{
  if(process.env.VUE_APP_SERVICE_TYPE == 'ZIU PAY')
  {
    return "aqua.ico";
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'PCX PAY')
  {
    return "PCXPAY.ico"
  }
  else if(process.env.VUE_APP_SERVICE_TYPE == 'SIGMA PAY')
  {
    return "SIGMAPAY.ico"
  }
}

const paths = {
  Ziupay: [
    {
      path: "/",
      redirect: "",
    },
    {
      path: "",
      name: "MainLayout",
      component: () => import("@/layout/ziupay/MainLayout"),
      children: [
        {
          path: "",
          name: "Home",
          component: () => import("@/views/ziupay/Home"),
        },
        {
          path: "/service",
          name: "Service",
          component: () => import("@/views/ziupay/Service"),
        },
        {
          path: "/faq",
          name: "Faq",
          component: () => import("@/views/ziupay/Faq"),
        },
        {
          path: "/contact",
          name: "Contact",
          component: () => import("@/views/ziupay/Contact"),
        },
      ],
      meta: {
        title: getTitle(),
        icon: getFavicon(),
      },
    },
  ],
};

export { paths };
